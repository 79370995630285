<template>

  <div class="card-body links" v-if="link.id">
    <CountryChoice
        v-bind:active_country="active_country"
        v-bind:countries="link.countries"
        v-bind:changeCountry="(country) => {active_country === country.code; countryFunc(link.id, country.code)}">
    </CountryChoice>
    <div id="legend-container" class="legend"></div>
    <div style="height: 430px">
      <Bar v-if="datacollection"
           :options="chartOptions"
           :data="datacollection"
           :plugins="[htmlLegendPlugin]"
      />
    </div>
    <div class="text-center h5">Статистика за текущий день</div>
    <div class="d-flex mb-3" style="overflow-x: scroll;">

      <ul class="list-group list-group-horizontal me-1">
        <li class="list-group-item">
          <p>
            <span class="b-info bg-success rounded-circle text-white"><i class="bi bi-arrow-up"></i></span>
            <span class="text-secondary">({{ diff_data.up.p || 0 }}%)
              </span>
          </p>
          <div class="h2">{{ diff_data.up.c || 0 }}</div>
        </li>
        <li class="list-group-item">
          <p>
            <span class="b-info bg-secondary rounded-circle text-white">=</span>
            <span class="text-secondary">({{ diff_data.eq.p || 0 }}%)
              </span>
          </p>
          <div class="h2">{{ diff_data.eq.c || 0 }}</div>
        </li>
        <li class="list-group-item">
          <p>
            <span class="b-info bg-danger rounded-circle text-white"><i class="bi bi-arrow-down"></i></span>
            <span class="text-secondary">({{ diff_data.down.p || 0 }}%)
              </span>
          </p>
          <div class="h2">{{ diff_data.down.c || 0 }}</div>

        </li>
      </ul>
      <ul class="list-group list-group-horizontal me-1">
        <li class="list-group-item">
          <p>Средняя</p>
          <div class="h2">
            {{ diff_data.middle || 0 }}
          </div>
        </li>
        <li class="list-group-item">
          <p class="no-wrap">1-10 <span class="text-secondary">({{ diff_data.visible.p || 0 }}%)</span></p>
          <div class="h2">{{ diff_data.visible.c }}</div>
        </li>
      </ul>
      <ul class="list-group list-group-horizontal">

        <li class="list-group-item" v-for="period in diff_data.period">
          <p>{{ period[0] }}-{{ period[1] }} <span class="text-secondary">({{ period[3] || 0 }}%)</span></p>
          <div class="h2">{{ period[2] || 0 }}</div>
        </li>
      </ul>
    </div>

    <div class="table-responsive">
      <table class="table table-hover table-bordered">
        <thead>
        <tr>
          <th scope="col" class="sticky">Запросы ({{ keywords_data.length }})</th>
          <th scope="col" v-for="date in link.dates" class="no-wrap">{{ date }}</th>
        </tr>
        </thead>
        <tbody class="table-group-divider">
        <tr v-for="keyword in keywords_data">
          <th scope="row" class="no-wrap sticky">{{ keyword.name }}</th>
          <td v-for="position in keyword.data"
              :class="`${position.diff&&position.pos?`bg-${position.diff > 0?'green':'pink'}`:''}`">
            {{ position.pos ? position.pos : '-' }}
            <sup v-if="position.diff&&position.pos"
                 :class="`text-${position.diff > 0?'success':'danger'}`">{{ position.diff }}</sup>
          </td>
        </tr>
        </tbody>
      </table>

    </div>
  </div>
</template>
<script>

import {Bar} from 'vue-chartjs'
import {Chart as ChartJS, Title, Tooltip, Legend, BarElement, CategoryScale, LinearScale} from 'chart.js'
import htmlLegendPlugin from "@/components/legendPlugin";

ChartJS.register(Title, Tooltip, Legend, BarElement, CategoryScale, LinearScale)

ChartJS.defaults.plugins.legend.display = false;

export default {
  name: "GistStat",
  props: {
    link: {type: Object, default: {}},
    engine: {type: String, default: 'yandex'},
    countryFunc: {type: Function, default: null},
    day_of_week: {type: Array, default: []},
  },
  components: {
    Bar
  },
  data() {
    return {
      htmlLegendPlugin: htmlLegendPlugin,
      active_country: {},
      period_params: [
        [1, 3, '#3780FF'],
        [4, 10, '#0DCE70'],
        [11, 20, '#BBEED5'],
        [21, 50, '#ACCBFB'],
        [51, 100, '#EAEAEA']]
    }
  },
  computed: {
    datacollection() {
      let $this = this,
          dataset = [], all_count = $this.keywords_data.length, dates = [], keywords = [];
      if (this.link.dates) this.link.dates.forEach((date, index) => {

      })
      let all_dates = $this.keywords_data.map(x => x.data)
      this.period_params.forEach(function (param) {
        let param_data = {
          label: `${param[0]}-${param[1]}`,
          backgroundColor: `${param[2]}`,
          borderRadius: 300,
          data: []
        }
        if ($this.link.dates) $this.link.dates.forEach(function (el, index) {
          let all_l = all_dates.map(x => x[index].pos).filter(x => {
            return param[0] <= x && x <= param[1]
          }).length
          param_data.data.push(Math.round(all_l / (all_count / 100)))
        })
        dataset.push(param_data)
      })
      console.log({labels: $this.link.dates, datasets: dataset})
      return {labels: $this.link.dates, datasets: dataset}
    },

    diff_data() {
      let diff = {up: 0, eq: 0, down: 0, middle: 0, visible: 0, period: []};
      let data = []
      this.keywords_data.forEach(function (key) {
        data.push(key.data[0])
      })
      let up = data.filter(x => {
        return x.diff > 0
      }).length
      diff.up = {c: up, p: Math.round(up / (data.length / 100))}
      let down = data.filter(x => {
        return x.diff < 0
      }).length
      diff.down = {c: down, p: Math.round(down / (data.length / 100))}
      let eq = data.filter(x => {
        return !x.diff
      }).length
      diff.eq = {c: eq, p: Math.round(eq / (data.length / 100))}
      diff.middle = Math.round(data.reduce((s, x) => s + x.pos, 0) / data.length)
      let vis = data.filter((x) => {
        return x.pos < 11
      }).length
      diff.visible = {c: vis, p: Math.round(vis / (data.length / 100))}
      let all_count = this.keywords_data.length / 100;
      this.period_params.forEach(function (param) {
        let all_l = data.map(x => x.pos).filter(x => {
          return param[0] <= x && x <= param[1]
        }).length
        diff.period.push([param[0], param[1], all_l, Math.round(all_l / all_count)])
      })
      return diff;
    },
    keywords_data() {
      let keys = [], $this = this;
      if (this.link.keywords) this.link.keywords.forEach(function (keyword) {
        let dates = keyword[$this.engine], sum = dates.reduce((s, d) => s + d, 0);

        if (sum > 0) {
          let calc_dates = []
          dates.forEach(function (el, index) {
            let diff = 0
            if (dates[index + 1]) {
              diff = dates[index + 1] - el
            }
            calc_dates.push({pos: el, diff: diff})
          })
          keys.push({name: keyword.name, data: calc_dates})
        }
      })
      return keys;
    },
    chartOptions() {

      return {
        responsive: true,
        maintainAspectRatio: false,
        aspectRatio: 1,
        scales: {
          x:
              {
                stacked: true,
                grid: {
                  display: false
                },
                ticks: {
                  autoSkip: false,
                },
              },
          y:
              {
                stacked: true,
                grid: {
                  display: false
                },
              },

        },

        plugins: {
          htmlLegend: {
            containerID: 'legend-container',
          },
          legend: {
            display: false,
          }
        }
      }
    },

  },
  mounted() {
    this.active_country = this.link.countries[0]
  },
  methods: {}
};
</script>

