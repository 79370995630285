<template>
    <main class="dashboard links">
        <div
                class="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pt-3 pb-2 mb-3 border-bottom">
            <h2>История изменений: {{ project.name }}</h2>
            <BackButton></BackButton>
        </div>

        <div class="card">

            <div class="card-body">

                <div class="table-responsive">
                    <table class="table mb-5">
                        <thead>
                        <tr>
                            <th scope="col" class="col-1">Дата</th>
                            <th scope="col">
                                <div class="row">
                                    <div class="col">Поле</div>
                                    <div class="col">Было</div>
                                    <div class="col">Стало</div>
                                </div>
                            </th>
                            <th scope="col" class="col-2">Пользователь</th>

                        </tr>
                        </thead>
                        <tbody>
                        <template v-for="record in history">
                            <tr>
                                <td>{{ $moment(record.date_create).format("DD.MM.YYYY HH:MM") }}</td>
                                <td>
                                    <div class="row pb-1" v-for="value in record.values">
                                        <div class="col-2">{{ value.field }}</div>
                                        <div class="col-5" v-html="value.was || '-'"></div>
                                        <div class="col-5" v-html="value.became"></div>
                                    </div>
                                </td>
                                <td>{{ record.user }}</td>


                            </tr>
                        </template>
                        </tbody>
                    </table>


                </div>
            </div>


        </div>

    </main>
</template>

<script>
import {ProjectApi} from '@/api/project'

export default {
    name: 'ProjectHistory',
    data() {
        return {
            history: [],
            project: {},
        }
    },

    mounted: function () {
        let $this = this;
        ProjectApi.get_simple($this.$route.params.id).then(response => $this.project = response);
        ProjectApi.get_history($this.$route.params.id).then(response => $this.history = response);
    },
}

</script>
