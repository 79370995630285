import { HTTP } from "./common";

export const DomainApi = {

  list(project_id) {
    return HTTP.get(`domains/?project_id=${project_id}`).then((response) => {
      return response.data;
    });
  },
  create(data) {
    return HTTP.post(`domains/`, data).then((response) => {
      return response.data;
    });
  },
  get(id) {
    return HTTP.get(`domains/${id}/`).then((response) => {
      return response.data;
    });
  },
  add(data) {
    return HTTP.post(`domains/add_domain/`, data).then((response) => {
      return response.data;
    });
  },
  delete(id) {
    return HTTP.delete(`domains/${id}/`).then((response) => {
      return response.data;
    });
  },
   get_stat_data(id, filter) {
    return HTTP.get(`domains/${id}/get_stat_data/?${filter ? filter : ""}`).then(
      (response) => {
        return response.data;
      }
    );
  },
   get_report_data(id, filter) {
    return HTTP.get(`domains/${id}/get_report_data/?${filter ? filter : ""}`).then(
      (response) => {
        return response.data;
      }
    );
  },


};
