<template>
  <div :class="`code-area ${layout}${shares.includes(code.id)?' fill':''}${detail?' detail':''}`" :id="'code-'+code.id">
    <div class="body">
      <button @click="copyCode" class="btn btn-sm">{{ code.now }}</button>
      <div class="timer">
        <svg width="30" height="30" viewBox="0 0 30 30" class="timer-progress">
          <circle class="bg"></circle>
          <circle class="fg"></circle>
        </svg>
        <span>{{ timer }}</span>
      </div>
    </div>
    <div class="issuer" @click="detail=!detail;">
      <span>{{ code.issuer }} </span>
      <small> {{ code.name }}</small>
      <small class="login" v-if="code.login">({{ code.login }})</small>
    </div>


    <div class="header">

      <div class="groups" :style="{width: `${width+150}px`}">
        <div class="group" v-for="group in code.groups">
          {{ group.name }}
          <a href="" @click.prevent="removeGroup(group)"> <i class="bi bi-x"></i></a>
        </div>
        <form @submit.prevent="createGroup" class="autocomplete">
          <input class="form-control" placeholder="Добавить группу"
                 :style="{paddingLeft: `${width}px`}" type="text"
                 v-model="searchTerm">
          <ul v-if="search.length">
            <li
                v-for="group in search"
                :key="group.name"
            >
              <a href="" @click.prevent="addGroup(group)"> {{ group.name }}</a>
            </li>
          </ul>
        </form>

      </div>
      <a href="" class="share" @click.prevent="addToShare(code.id)"> <i
          :class="`bi bi-share${shares.includes(code.id)?'-fill':''}`"></i></a>
      <button class="btn btn-sm edit_btn" @click.prevent="showMenu=!showMenu"><i
          class="bi bi-three-dots-vertical"></i></button>
      <ul class="edit-select" v-if="showMenu">
        <li><a href="" @click.prevent="deleteCode">Удалить</a></li>
        <li><a href="" @click.prevent="$modal('show', 'code_modal-' + code.id); showMenu=false;">Редактировать</a>
        </li>
      </ul>
    </div>

    <div class="detail-info" v-if="detail">
      <p><b>{{ code.name }}</b></p>
      <p v-if="code.issuer"><b>Эмитент:</b>{{ code.issuer }}</p>
      <p><b>Описание:</b>{{ code.description }}</p>
      <p><b>Код:</b> <span @click="show_secret=!show_secret" :class="show_secret?'':'mute'">{{ code.secret }}</span></p>
      <p><b>Количество цифр:</b> {{ code.digits }}</p>

      <p v-if="code.initial_count"><b>HTOP:</b> {{ code.initial_count }}</p>
      <p v-else><b>TOPT:</b> {{ code.interval }}</p>
      <p><b>Логин:</b> {{ code.login }}</p>
      <p><b>Пароль:</b> <span @click="show_pass=!show_pass" :class="show_pass?'':'mute'">{{ code.pwd }}</span></p>
    </div>
    <modal :modal_id="'code_modal-'+code.id">
      <template v-slot:header>
        <h2>Редактирование</h2>
      </template>

      <template v-slot:body>
        <div class="buttons">
          <form action="" @submit.prevent="createCode" class="manual">
            <label>Название
              <input type="text" placeholder="Название" class="form-control" v-model="code.name">
            </label>
            <label>Эмитент
              <input type="text" placeholder="Эмитент" class="form-control" v-model="code.issuer">
            </label>
            <label>Количество цифр
              <input type="number" placeholder="Количество цифр" class="form-control" v-model="code.digits">
            </label>
            <label v-if="code.initial_count">HTOP
              <input type="number" placeholder="Начальное значение счетчика" class="form-control"
                     v-model="code.initial_count">
            </label>
            <label v-if="code.interval">TOPT
              <input type="number" placeholder="Интервал" class="form-control" v-model="code.interval">
            </label>

            <label>Логин
              <input type="text" placeholder="Логин" class="form-control" v-model="code.login">
            </label>
            <label>Пароль
              <div class="password">
                <input class="form-control" :type="show_pass?'text':'password'" name="password"
                       v-model="code.pwd" placeholder="Пароль">
                <i v-on:click="show_pass=!show_pass" :style="{color: show_pass?'#000':'#ccc'}"
                   :class="`bi bi-eye${show_pass?' active':''}`"></i>
              </div>
            </label>
            <label>Код
              <div class="password">
                <input class="form-control" :type="show_secret?'text':'password'" name="secret"
                       v-model="code.secret" placeholder="Код">
                <i v-on:click="show_secret=!show_secret" :style="{color: show_secret?'#000':'#ccc'}"
                   :class="`bi bi-eye${show_secret?' active':''}`"></i>
              </div>
            </label>
            <label>Описание
              <textarea cols="30" rows="5" v-model="code.description" placeholder="Описание"></textarea>
            </label>
            <button class="btn btn-sm btn-primary" type="submit">Сохранить</button>
          </form>
        </div>

      </template>

      <template v-slot:footer>
        <button type="button" class="btn btn-secondary" @click="$modal('hide', 'code_modal-'+code.id);"
                data-bs-dismiss="modal">Отмена
        </button>
      </template>
    </modal>
  </div>

</template>

<script>
import {AuthyApi} from "@/authy/api/authy";
import {QrCapture, QrDropzone, QrStream} from "vue3-qr-reader";

export default {
  name: 'CodeSmall',
  components: {QrDropzone, QrCapture, QrStream},

  props: {
    layout: {type: String},
    code: {type: Object},
    groups: {type: Array},
    shares: {type: Array},
    addToShare: {type: Function},
    removeCode: {type: Function},
    saveCode: {type: Function},
    getCode: {type: Function}
  },
  data() {
    return {
      timer: 0,
      add_croup: false,
      searchTerm: "",
      showMenu: false,
      show_pass: false,
      show_secret: false,
      timer_start: 0,
      timer_interval: 1,
      detail: false,
      intervalId: null,

    }
  },
  computed: {
    width() {
      return this.code.groups.map(x => x.name.length * 5 + 45).reduce((a, x) => a + x, 0) + 15;
    },
    search() {
      const obj_groups = this.code.groups.map(x => x.id)
      if (this.searchTerm.length < 1) {
        return []
      }
      return this.groups.filter(group => {
        return !obj_groups.includes(group.id) && group.name.toLowerCase()
            .startsWith(this.searchTerm.toLowerCase())
      })
    },
  },
  mounted() {
    this.getCode(this.code.id)
    this.getTimer()
    setInterval(() => this.timer_start += this.timer_interval, 100);
  },
  watch: {
    timer(value) {
      if (value <= 0) this.getDetail()

    },
    "code.now"() {
      this.getTimer()
    }
  },

  methods: {
    getTimer() {
      this.timer = Math.abs(this.code.interval - parseInt(Math.floor(+new Date().getTime() / 1000 % this.code.interval)));
      this.timer_start = 100 - parseFloat(this.timer / this.code.interval * 100)
      this.timer_interval = parseFloat(10 / this.code.interval)
      if (this.intervalId) clearInterval(this.intervalId);
      this.intervalId = setInterval(() => this.timer -= 1, 1000);
    },
    getDetail() {
      this.getCode(this.code.id)
    },
    createCode() {
      let code = JSON.parse(JSON.stringify(this.code));
      delete code.users;
      delete code.groups;
      delete code.now;
      AuthyApi.patch(this.code.id, code).then(() => this.$modal('hide', 'code_modal-' + this.code.id));
    },
    deleteCode() {
      AuthyApi.delete(this.code.id).then(() => this.removeCode(this.code.id))
    },
    updateGroup() {
      AuthyApi.patch(this.code.id, {groups: this.code.groups.map(x => x.id)})
    },
    removeGroup(group) {
      this.code.groups.splice(this.code.groups.findIndex(x => x.id === group.id), 1);
      this.updateGroup()
    },
    addGroup(group) {
      this.code.groups.push(group)
      this.searchTerm = "";
      this.updateGroup()
    },
    createGroup() {
      if (this.searchTerm.length) {
        const $this = this;
        AuthyApi.add_groups({name: this.searchTerm}).then(response => {
          $this.groups.push(response);
          $this.addGroup(response)
        })
      }

    },
    copyCode() {
      try {
        navigator.clipboard.writeText(this.code.now);
        this.$notify({
          type: 'success',
          text: "Добавленно в буфер обмена"
        });
      } catch ($e) {
        this.$notify({
          type: 'error',
          text: "Копирование не доступно"
        });
      }
    }
  }
}
</script>
<style lang="scss" scoped>
//@import '@venegrad/vue3-autocomplete/dist/venAutocomplete.css';

.code-area {
  display: flex;
  border: 1px solid rgba(234, 234, 234, 1);
  box-shadow: 0 0 8.8px 0 rgba(0, 0, 0, 0.27);
  //backdrop-filter: blur(11px);
  border-radius: 10px;
  padding: 3px 20px;
  gap: 20px;
  position: relative;
  transform: none !important;

  &.sortable-chosen {
    background: rgba(252, 189, 6, 0.2);
  }

  &.fill {
    background: rgba(234, 234, 234, 1);
  }

  &:hover {
    z-index: 10;
  }

  &.editor {


    .header {

      .share, .groups, .edit_btn {
        display: none;
      }

    }

    body {
      order: 0;
    }

    .footer {
      order: 2;
      align-items: center;
      margin-left: auto;
      gap: 10px;
    }
  }

  &.detail {
    flex-wrap: wrap;

    .detail-info {
      width: 100%;
      line-break: anywhere;
    }
  }

  &.grid-3x3-gap {
    flex-direction: column;
    width: 250px;
    min-height: 250px;
    margin-bottom: auto;

    .body {
      text-align: center;
      height: 150px;
      display: flex;
      align-items: center;
      justify-content: center;
      z-index: 1;

      .timer {
        position: absolute;
        left: 50%;
        transform: translate(-50%, 0);

        svg {
          width: 130px;
          --stroke-width: 2px;
          height: 130px;
        }

        span {
          position: absolute;
          top: 40px;
        }
      }
    }

    .issuer {
      flex-direction: column;
      gap: 2px;
      overflow: hidden;
    }


    .header {
      position: absolute;
      width: 100%;
      margin: 0;
      left: 0;
      padding: 0 10px;
      z-index: 10;

      .share {
        //position: absolute;
        //bottom: 10px;
        //right: 10px;
      }

      .groups {
        display: none;
      }
    }

  }


  .body {
    display: flex;
    align-items: center;

    button {
      font-family: "Arial";
      letter-spacing: 2px;
      font-size: 30px;
      font-weight: bold;
      color: rgba(55, 128, 255, 1);
      cursor: pointer;
      z-index: 10;
    }
  }

  .issuer {
    display: flex;
    gap: 10px;
    align-items: center;
    cursor: pointer;
    font-size: 20px;
    font-weight: bold;
    color: rgba(139, 139, 139, 1);

    small {
      font-weight: normal;
    }

  }

  .header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 20px;
    position: relative;
    margin-left: auto;

    .edit_btn {
      font-size: 20px;
    }

    .edit-select {
      position: absolute;
      right: 0;
      top: 40px;
      z-index: 10;
      border: 1px solid;
      background: #fff;
      font-size: 14px;
      font-weight: normal;
      list-style: none;
      padding: 6px;
      border-radius: 3px;
    }


    .autocomplete {
      z-index: 1;
      position: absolute;

      ul {
        position: absolute;
        border: 1px solid #ccc;
        list-style: none;
        padding: 10px;
        font-size: 15px;
        font-weight: normal;
        background: #fff;
        width: 90%;
        max-height: 160px;
        overflow-y: auto;

        a {
          width: 100%;
          display: block;

          &:hover {
            background: #ccc;
          }

        }
      }

      input {
        width: 90%;
        border-color: #ccc;
        box-shadow: none;
        border-radius: 10px;
        height: 33px;

        &::placeholder {
          color: rgba(139, 139, 139, 1);
        }
      }
    }

    .groups {
      display: flex;
      gap: 5px;
      align-items: center;
      margin-left: auto;
      position: relative;

      .group {
        border: 1px solid rgba(139, 139, 139, 1);
        border-radius: 10px;
        font-size: 13px;
        font-weight: normal;
        padding: 5px;
        z-index: 2;
        background: rgba(139, 139, 139, 1);
        color: #fff;
        height: 28px;
        line-height: 10px;
        margin-left: 3px;
      }

      button {
        display: flex;
        align-self: center;
      }
    }

  }


  .footer {
    display: flex;
    justify-content: space-between;
    color: rgba(139, 139, 139, 1);
  }
}

.mute {
  background: #ccc;
  color: #ccc;
  cursor: pointer;
}

.timer {
  position: relative;

  .timer-progress {
    --size: 30px;
    --timer: 30s;
    --half-size: calc(var(--size) / 2);
    --stroke-width: 5px;
    --radius: calc((var(--size) - var(--stroke-width)) / 2);
    --circumference: calc(var(--radius) * pi * 2);
    --dash: calc((v-bind("timer_start") * var(--circumference)) / 100);
    //animation: progress-animation var(--timer) linear 0s 1 forwards;
    //animation-iteration-count: infinite;


    circle {
      cx: var(--half-size);
      cy: var(--half-size);
      r: var(--radius);
      stroke-width: var(--stroke-width);
      fill: none;
      stroke-linecap: round;

      &.bg {
        stroke: #ddd;
      }

      &.fg {
        transform: rotate(-90deg);
        transform-origin: var(--half-size) var(--half-size);
        stroke-dasharray: var(--dash) calc(var(--circumference) - var(--dash));
        transition: stroke-dasharray 0.3s linear 0s;
        stroke: #5394fd;
      }
    }
  }

  span {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    font-weight: bold;
  }
}

.buttons {
  display: flex;
  flex-direction: column;
  gap: 30px;

  form {
    margin: 20px;
    padding: 20px;
    display: flex;
    flex-direction: column;
    gap: 20px;
    text-align: center;
    border: 1px solid;
    border-radius: 20px;

    ::placeholder {
      color: rgba(139, 139, 139, 1);
    }

    label {

      text-align: left;
      display: flex;
      flex-direction: column;
      width: 100%;
      justify-content: start;
      align-items: self-start;

      input, textarea, .password {
        width: 100%;
      }
    }
  }

}


@property --progress {
  syntax: "<number>";
  inherits: false;
  initial-value: 0;
}

@keyframes progress-animation {
  from {
    --progress: v-bind('timer_start');
  }
  to {
    --progress: 100;
  }
}

@media (width < 768px) {

  .code-area {
    flex-direction: column;
    gap: 5px;

    .issuer {
      flex-direction: column;
      font-size: 1rem;
      align-items: start;
      flex-wrap: wrap;
      line-height: 10px;
    }

    .header {
     position: static;
      height: 35px;
      .share{
        position: absolute;
        left: 5px;
        top: 5px;
      }
      .edit_btn{
        position: absolute;
        right: 0;
        top: 0;
      }
    }

    &.grid-3x3-gap {
      width: 100%;
      min-height: auto;
    }

  }
}

</style>

