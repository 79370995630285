<template>
    <ul class="nav nav-tabs mb-3">
        <li class="nav-item" v-for="menu in menu_list.main">
            <router-link v-if="$store.getters.permission.includes(menu.path.name)" :to="menu.path" :class="`nav-link ps-2 pe-2 ${route === menu.path.name?'active':''}`">
                {{ menu.name }}
            </router-link>
        </li>

        <li class="nav-item" v-if="menu_list.dropdown.length">
            <div class="dropdown p-2">
                <a class="dropdown-toggle p-0" href="#" id="dropdownProjectMenuLink" data-bs-toggle="dropdown"
                   aria-expanded="false">
                    <i class="bi bi-list"></i>
                </a>
                <ul class="dropdown-menu" aria-labelledby="dropdownProjectMenuLink">
                    <li class="nav-item" v-for="menu in menu_list.dropdown">
                        <router-link v-if="$store.getters.permission.includes(menu.path.name)" :to="menu.path" active-class="active" class="nav-link">
                            {{ menu.name }}
                        </router-link>
                    </li>
                </ul>
            </div>
        </li>

        <li style="margin-left: auto">
             <router-link :to="{name: 'project_history', params:{id: $route.params.id}}" class="btn btn-outline-warning"><i class="bi bi-clock-history me-2"></i>история изменений</router-link>
        </li>
    </ul>

</template>

<script>
export default {
    name: "SettingMenu", // todo merge and delete
    props: ['id', 'route', 'role', 'project'],
    data: () => ({
        width: window.innerWidth,
    }),
    computed: {
        menu_list() {
            let menu_list = [
                {name: 'Основные настройки', show: true, path: { name: 'project_edit', params: { id: this.id }}},
                {name: 'Настроки tr', show: true, path: { name: 'project_tr_setting', params: { id: this.id }}},
                {name: 'Настроки ton-online', show: true, path: { name: 'project_ton_online_setting', params: { id: this.id }}},

            ]
            menu_list = menu_list.filter(x=>x.show)
            let sizes = {1920: 12, 1750: 10, 1600: 10, 1550: 7, 1440: 7, 1200: 6, 1100: 4}
            let count = Object.keys(sizes).filter(x=>x<=this.width).sort().reverse()[0]
            count = count?sizes[count]:2
            return {main: menu_list.slice(0, count), dropdown: menu_list.splice(count)}
        }
    },
    created() {
        window.addEventListener("resize", this.changeWidth);
    },
    destroyed() {
        window.removeEventListener("resize", this.changeWidth);
    },
    methods: {
        changeWidth(e) {
            this.width = window.innerWidth
        }
    }
}
</script>

<style scoped>

</style>
