<template>
  <main class="projects links" v-if="project.id">
    <h3>Выдача изображений: {{ project.name }}
    </h3>
    <div class="col justify-content-start align-items-baseline d-flex mb-2">

      <router-link v-if="$store.getters.permission.indexOf('project_edit')+1" type="button"
                   :to="`/projects/${$route.params.id}/edit/`"
                   class="btn btn-sm btn-dark">
        Редактировать
      </router-link>


      <router-link type="button" class="btn  btn-outline-primary ms-2 btn-sm"
                   :to="`/projects/${$route.params.id}/pictures/uniques/`">Уникальные изображения
      </router-link>


    </div>
    <ProjectInfo :project.sync="project"></ProjectInfo>


    <div class="card mt-3">
      <div class="card-body">

        <div class="d-flex justify-content-start align-items-baseline">
          <h4 class="no-wrap me-2">Сводные данные </h4>
          <date-picker v-if="active_date"
                       style="width: 150px;"
                       locale="ru"
                       :clearable="false"
                       v-model="active_date"
                       format="dd.MM.yyyy"
                       model-type="yyyy-MM-dd"
                       auto-apply
                       :allowed-dates="allowedDates"
                       :enable-time-picker="false">
          </date-picker>
        </div>
        <detail_info
            v-bind:project="project"
            v-bind:date="active_date"
            v-bind:funcAll="getFull"
            v-bind:state="state"
            v-bind:full_info_show="full_info_show"
            v-bind:active_country="active_country"
            :allowedDates="allowedDates"
        >
        </detail_info>

        <div class="project-controls d-flex mb-3">
          <div class="controls d-flex">
            <date-picker
                v-model="active_date"
                locale="ru"
                :clearable="false"
                format="dd.MM.yyyy"
                model-type="yyyy-MM-dd"
                auto-apply
                :allowed-dates="allowedDates"
                :enable-time-picker="false"
            ></date-picker>
            <MultiSelect
                v-model="state"
                :options="$STATES"
                :only_value="true"
                :multi="false"
                name="Топ"
            ></MultiSelect>
            <MultiSelect
                v-if="keywords.length"
                v-model="keyword"
                :options="keywords"
                :only_value="true"
                :multi="true"
                name="Запрос"
            ></MultiSelect>
            <MultiSelect
                v-if="$PICTURE_TYPE"
                v-model="picture_type"
                :options="$PICTURE_TYPE"
                :multi="true"
                :only_value="true"
                name="Тип изображения"
            ></MultiSelect>
            <button type="button" v-for="eng in engines"
                    @click="engine = eng"
                    :class="'btn btn-sm no-wrap ' + (engine === eng?'btn-dark':'btn-outline-dark')">
              <i :class="eng"></i> {{ eng }}
            </button>

            <button @click="downloadImages" class="btn btn-sm btn-outline-dark" title="Скачать Блок картинки">
              <i class="bi bi-file-arrow-down"></i>
            </button>
          </div>

        </div>
        <div class="card-body border border-primary pt-0">

          <div class="table-responsive d-flex position-relative">
            <table class="table">
              <thead>
              <tr class="sticky-top">
                <th scope="col" class="no-wrap">Позиция</th>
                <th scope="col" class="no-wrap">Позиция в блоке</th>
                <th scope="col" class="no-wrap">Ссылка</th>
                <th scope="col" class="no-wrap">Тон ссылки</th>
                <th scope="col" class="no-wrap">Запрос</th>
                <th scope="col" class="no-wrap">Тип блока</th>
                <th scope="col" class="no-wrap">Ссылка изображения</th>
                <th scope="col" class="no-wrap">Тон изображения</th>
              </tr>
              </thead>
              <tbody>
              <tr v-if="empty_text">
                <td colspan="6" class="text-center"><h5>{{ empty_text }}</h5></td>
              </tr>
              <tr v-for="(result, index) in filter_results">
                <td class="engine_info">{{ result.place }}</td>
                <td>
                  {{
                    filter_results[index + 1] && filter_results[index + 1].position === result.position || filter_results[index - 1] && filter_results[index - 1].position === result.position ? '-' : result.position
                  }}
                  <span class="text-white">{{ result.position }}</span>
                </td>
                <td class="text-start no-wrap">
                  <popper hover>
                  <span slot="reference">
                    <span :style="$func.getClass(result.link_obj?.tone, false)">{{ $func.getDomain(result.url) }}</span>
                  </span>
                    <template #content>
                      <div class="popper text-start">
                        Тон: <span :style="$func.getClass(result.link_obj?.tone, false)">{{
                          result.link_obj?.tone ? $TONES[result.link_obj?.tone]?.name : 'Неразмечена'
                        }}</span>
                        <br>Ссылка: <a :href="result.url" target="_blank">{{ result.url }}</a>
                        <br>
                      </div>
                    </template>

                  </popper>

                </td>
                <td>
                  <ul class="dropdown list">
                    <template v-if="result.link_obj">
                      <template v-for="(tone, key) in $TONES">
                        <li v-if="!tone.no_filter"
                            v-on:click="result.link_obj.tone = key; updateLink(result);"
                            :class="`dropdown-item ${(tone.synonym.indexOf(result.link_obj.tone)+1)?`active bg-${key}`:''}`">
                          {{ tone.name }}
                        </li>
                      </template>
                    </template>
                    <template v-else>
                      <li :class="`dropdown-item active bg-${result.tone}`">
                        {{ result.get_tone_display || "Неразмечена" }}
                      </li>
                    </template>
                  </ul>
                </td>
                <td>{{ result.keyword.key }}</td>
                <td>{{ result.get_type_picture_display }}</td>
                <td>
                  <a :href="result.image_link" target="_blank">
                    <img :src="result.image_link" alt="" width="150">
                  </a>

                </td>
                <td>
                  <ul class="dropdown list">
                    <template v-if="result.img_obj">
                      <template v-for="(tone, key) in $TONES">
                        <li v-if="!tone.no_filter"
                            v-on:click="result.img_obj.tone = key; changeTone(result.img_obj);"
                            :class="`dropdown-item ${(tone.synonym.indexOf(result.img_obj.tone)+1)?`active bg-${key}`:''}`">
                          {{ tone.name }}
                        </li>
                      </template>
                    </template>
                    <template v-else>
                      <li :class="`dropdown-item active bg-${result.tone}`">
                        {{ result.get_tone_display || "Неразмечена" }}
                      </li>
                    </template>
                  </ul>
                </td>

              </tr>

              </tbody>
            </table>


          </div>
        </div>

      </div>
    </div>


  </main>
</template>

<script>
import SmallReport from '@/components/SmallReport';
import ProjectInfo from "@/components/ProjectInfo.vue";
import detail_info from "@/views/projects/detail/includes/Serm.vue";
import moment from "moment/moment";
import {LinkApi, ProjectApi} from "@/api/project";
import {PictureApi} from "@/api/pictures";
import MultiSelect from "@/plugins/Select.vue";


export default {
  name: 'Pictures',
  components: {
    MultiSelect,
    detail_info,
    ProjectInfo,
    SmallReport
  },
  data() {
    return {
      active_date: null,
      old_active_date: null,
      state: 10,
      project: {},
      full_info: false,
      full_info_show: false,
      image_stat: null,
      keywords: [],
      results: [],
      empty_text: '',
      old_active_country: null,
      keyword: [],
      allowedDates: [],
      engine: "yandex",
      picture_type: [this.$PICTURE_TYPE[0].id]
    }
  },
  computed: {
    active_country() {
      return this.project?.active_country_code
    },
    date_query() {
      if (this.active_date.includes("/")) return moment(this.active_date, 'DD/MM/YYYY').format("YYYY-MM-DD");
      return this.active_date;
    },
    filter_results() {
      let results = this.results
      if (this.engine) results = results.filter(x => x.keyword.engine === this.engine)
      if (this.picture_type.length) results = results.filter(x => this.picture_type.indexOf(x.type_picture)+1)
      if (this.state) results = results.filter(x => x.place <= this.state)
      if (this.keyword.length)  results = results.filter(x => this.keyword.indexOf(x.keyword?.id)+1)
      this.empty_text = !results.length ? 'Выдача по заданным параметрам отсутствует' : '';
      return results;
    },
     engines() {
      let engines = this.$ENGINES.filter(x => this.active_country ? this.active_country[x] : true);
      this.engine = engines[0]
      return engines
    }
  },
  watch: {
    active_country: function (val) {
      if (this.old_active_country !== null && this.old_active_country?.id !== val?.id) this.getProject();
      this.old_active_country = val;
    },
    active_date: function (val) {
      if (this.old_active_date !== null) this.getProject();
      this.old_active_date = val;
    }
  },
  mounted: function () {
    let $this = this;
    ProjectApi.get_simple($this.$route.params.id).then(response => {
      $this.project = response;
      if (!$this.active_date) {
        $this.active_date = moment($this.project.last_parser, "DD/MM/YYYY").format("YYYY-MM-DD");
      }
      this.getProject();
    });
    ProjectApi.get_avail_date_calendar(this.$route.params.id).then(response => this.allowedDates = response)

  },
  methods: {
    changeCountry: function (country) {
      if (this.active_country.code !== country.code) {
        this.active_country = country;
        this.getProject();
      }
    },
    getProject: function () {
      let $this = this;
      ProjectApi.get($this.$route.params.id, $this.date_query, $this.active_country.code || 'RU').then(response => {
        $this.project = {...$this.project, ...response};
        $this.project = {...$this.project, ...$this.project.stats};
        PictureApi.detail_project($this.$route.params.id, $this.date_query, $this.active_country.code).then(response => {
          $this.project.positive = {...$this.project.positive, ...response.stats.positive};
          $this.project.negative = {...$this.project.negative, ...response.stats.negative};
          $this.project.no_mark_pict = response.stats.no_mark_pict;
        });
      });
      this.getKeywords();
      this.getResults()
    },
    getKeywords: function (id) {
      let $this = this;
      PictureApi.keywords($this.$route.params.id, $this.active_country.code).then(response => {
        $this.keywords = response;

      })
    },
    getResults: function () {
      let $this = this;
      PictureApi.list($this.$route.params.id, $this.date_query, $this.active_country.code).then(response => {
        $this.results = response;
        for (const engine of $this.$ENGINES) {
          if (this.results.filter(x => x.engine === engine && (!this.picture_type.length || this.picture_type && (this.picture_type.indexOf(x.type_picture)+1))).length){
            $this.engine = engine;
            break;
          }
        }
      })
    },
    getFull: function () {
      let $this = this;
      if (!$this.full_info) {
        ProjectApi.get_full($this.$route.params.id, $this.date_query, $this.active_country.code || 'RU').then(response => {
          $this.full_info = true;
          $this.full_info_show = true;
          $this.project = {
            ...$this.project,
            ...response
          };
        });
      } else $this.full_info_show = !$this.full_info_show;
    },
     updateLink: function (picture,  update = false) {
      let $this = this;
      // let link_index = $this.pictures.findIndex(x => x.id === link.id);
      LinkApi.update({tone: picture.link_obj.tone, id: picture.link_obj.id}).then(response => {
        picture.link_obj = response;
        $this.$notify({
          type: 'success ',
          text: 'Данные обновлены'
        });
      })
    },

    changeTone: function (result) {
      let $this = this;
      $this.results.forEach(function (e) {
        if (e.img_obj?.id && e.img_obj?.id === result.id) {
          e.tone = result.tone;
        }
      })
      PictureApi.update(result.id, result).then(response => {
        $this.$notify({
          type: 'success ',
          text: 'Данные обновлены'
        });
      })
    },

    fileResponse: function (response, name) {
      let element = document.createElement('a');
      element.setAttribute('href', 'data:text/plain;base64,' + response);
      element.setAttribute('download', name);
      element.style.display = 'none';
      document.body.appendChild(element);
      element.click();
      document.body.removeChild(element);
    },
    downloadImages() {
      PictureApi.download_images(this.$route.params.id).then(response => this.fileResponse(response, `pictures-${this.$route.params.id}.zip`))
    }

  }
}

</script>

<style scoped>
.date {
  height: 100px;
  margin: 20px 0;
}
</style>

