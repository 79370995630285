import TextFront from '../TextFront.vue'
import TextProject from '../TextProject.vue'


export default [
    {
        path: '/text',
        name: 'unique_text',
        component: TextFront,
        meta: {
            requiresAuth: true,
            base_name: "unique_text",
            // hasPerm: true,
            title: 'Уникальные тексты',
            // hasPerm: true
        },
        children: [
            {
                path: ':text_id',
                name: 'text_project_detail',
                component: TextProject,
                meta: {
                    base_name: "unique_text",
                    requiresAuth: true,
                    // hasPerm: false
                }
            },
        ]
    }
]
