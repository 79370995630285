<template>
  <main class="dashboard stats">
    <div class="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pt-3 pb-2 mb-3">
      <h1>Проверка позиций</h1>
      <button @click.prevent="create=true" class="btn btn-sm btn-outline-dark">Добавить</button>
      <BackButton></BackButton>
    </div>
    <form v-if="create" action="" @submit.prevent="createDomain" class="d-flex create-form">
      <label for="">Домен</label>
      <input v-model="new_name" required type="text" class="form-control">
      <button type="submit" class="btn btn-sm btn-outline-success">Добавить</button>
      <button type="button" @click.prevent="create=false" class="btn btn-sm btn-outline-secondary">Отмена</button>
    </form>
    <div class="card">
      <ul class="list-group">
        <li class="list-group-item d-flex justify-content-between align-items-start" v-for="domain in domains">
          <div class="ms-2 me-auto">
            <router-link :to="`/projects/${this.$route.params.id}/domains/${domain.id}/`" class="fw-bold">{{ domain.name }}</router-link>
          </div>

          <DomainSmall v-if="active_country && dates.length" :dates="dates" :keywords="keywords" :country="active_country.code" :domain="domain"></DomainSmall>
          <button @click="deleteDomain(domain)" class="text-danger btn btn-sm"><i class="bi bi-trash3"></i></button>
        </li>

      </ul>
    </div>

  </main>


</template>

<script>

import {DomainApi} from "@/api/domains";
import DomainSmall from "@/views/projects/domains/DomainSmall.vue";
import {ProjectApi} from "@/api/project";
import {PictureApi} from "@/api/pictures";

export default {
  name: 'DomainStat',
  components: {DomainSmall},
  data() {
    return {
      domains: [],
      dates: [],
      keywords: [],
      active_country: null,
      new_name: null,
      create: false
    }
  },
  mounted() {
    const $this = this;
    ProjectApi.get_simple($this.$route.params.id).then(pr => {
      $this.active_country = pr.countries[0]
      ProjectApi.get_avail_date_calendar(this.$route.params.id).then(response => {
        $this.dates = response.sort().reverse().slice(0, 2).reverse();
        PictureApi.keywords(this.$route.params.id, this.active_country.code).then(r => {
          $this.keywords = r;
          DomainApi.list(this.$route.params.id).then(r => $this.domains = r)
        });
      })
    })

  },
  methods: {
    deleteDomain(domain){
      let index = this.domains.findIndex(x=>x.id===domain.id);
      DomainApi.delete(domain.id).then(()=>this.domains.splice(index, 1))
    },
    createDomain(){
      const $this = this;
      DomainApi.create({project: this.$route.params.id, name: this.new_name}).then(r=>{
        $this.domains.push(r)
        $this.new_name = ""
        $this.create = false
      })
    }


  }
}

</script>
<style scoped lang="scss">
.create-form{
  display: flex;
  gap: 10px;
  align-items: center;
  justify-content: end;
  margin-bottom: 20px;
  input{
    width: 250px;
  }
}
</style>
