<template>
  <div>

    <div class="part mb-3" v-if="!!settings">
      <div class="buttons" v-if="project.project_type === 'audit' && $store.getters.permission.indexOf('full_research') + 1">
        <date-picker style="width: 250px" v-model="date"
                     locale="ru"
                     :clearable="false"
                     format="dd.MM.yyyy"
                     model-type="yyyy-MM-dd"
                     auto-apply
                     :enable-time-picker="false"></date-picker>
        <button class="btn btn-sm btn-outline-dark" @click="!date?$notify({type: 'error', text: 'Выберите дату'}):$modal('show', 'change_date');">Изменить дату аудита
        </button>
      </div>
      <h4>Страны</h4>
      <div class="buttons">
        <template v-for="country in settings.countries">
          <input :disabled="$moment.duration($moment().diff($moment(country.last_parser))).asMinutes()<=60" type="radio"
                 class="btn-check" v-model="config.country" @click="config.country?.s_id === country.s_id?config.country=null:null" :value="country" name="country" :id="'option'+country.id"
                 autocomplete="off">
          <label class="btn btn-outline-dark d-flex" :for="'option'+country.id">
  <span class="flag">
              <i :class="`${country.code.toLowerCase()}`" :title="country.name"></i>
              </span>
            {{ country.name }}
            <span class="date" v-if="country.last_parser">{{ $moment(country.last_parser).format("DD.MM H:m") }}</span>
          </label>
        </template>


      </div>
      <hr>
      <h4>Поисковые системы</h4>
      <div class="buttons">
        <template v-for="engine in engines">
          <input type="checkbox" class="btn-check" v-model="config.engine" :value="engine" name="engine" :id="engine"
                 autocomplete="off">
          <label class="btn btn-outline-dark d-flex" :for="engine"><i :class="engine"></i> {{ engine }}</label>
        </template>
      </div>
      <hr>
      <h4>Запросы</h4>
      <div class="buttons">
        <template v-for="keyword in keys">
          <input :disabled="$moment.duration($moment().diff($moment(keyword.last_parser))).asMinutes()<=60"
                 type="checkbox" class="btn-check" v-model="config.key" name="keyword" :value="keyword.id"
                 :id="'key'+keyword.id" autocomplete="off">
          <label class="btn btn-outline-dark d-flex" :for="'key'+keyword.id">{{ keyword.name }}
            <span class="date" v-if="keyword.last_parser">{{ $moment(keyword.last_parser).format("DD.MM H:m") }}</span>
          </label>
        </template>
      </div>
    </div>
    <div class="buttons">
      <button type="button" class="btn btn-secondary" @click="$modal('hide', 'restart_settings');"
              data-bs-dismiss="modal">Отмена
      </button>
      <button type="button" class="btn btn-sm btn-outline-dark" @click="restartParser">Перезапустить</button>
      <button type="button" class="btn btn-sm btn-outline-dark" v-if="$store.getters.permission.indexOf('full_research') + 1" @click="contentSearch">Поиск контента</button>
      <button type="button" class="btn btn-sm btn-outline-dark" v-if="$store.getters.permission.indexOf('full_research') + 1" @click="bindLinks">bind_links</button>

    </div>
    <modal modal_id="change_date" class="warning">
      <template v-slot:header>
        <h4>Внимание</h4>
      </template>

      <template v-slot:body>
        <h5>Подтверждение изменение даты аудита.</h5>
        <h6>Дата изменится во всей собраннной ранее выдаче</h6>
      </template>
      <template v-slot:footer>
        <button type="button" class="btn btn-secondary" @click="$modal('hide', 'change_date');"
                data-bs-dismiss="modal">Отмена
        </button>
        <button type="button" class="btn btn-danger" v-on:click="changeAuditDate();">Подтвердить</button>
      </template>
    </modal>
  </div>
</template>

<script>
import {ProjectApi} from "@/api/project";
import moment from "moment/moment";

export default {
  name: "RestartParser",
  props: {
    project: Object
  },
  components: {},
  data() {
    return {
      settings: null,
      keywords: [],
      date: null,
      config: {country: null, engine: [], key: []}
    }
  },
  watch:{
    "config.country"(){
      this.config.engine = [];
      this.config.key = [];
    }
  },
  computed: {
    engines() {
      return this.$ENGINES.filter(x => this.config.country ? !!this.config.country[x] : true)
    },
    keys() {
      return this.settings.keys ? this.settings.keys.filter(key => (key.countries.length && this.config.country) ? !!key.countries.find(x => x.s_id === this.config.country.s_id) : true) : []
    },
  },
  mounted() {
    const $this = this;
    ProjectApi.light_keywords(this.project.id).then(response => {
      $this.keywords = response
      $this.getProjectState();
    })
  },
  methods: {
    getProjectState(){
      const $this = this;
      ProjectApi.project_state($this.project.id).then(response => {
        $this.settings = {countries: [], keys: []}
        Object.keys(response.countries).forEach(country_code => {
          let project_country = $this.project.countries.find(c => c.code === country_code);
          project_country.last_parser = response.countries[country_code]
          $this.settings.countries.push(project_country)
        })
        Object.keys(response.keys).forEach(key_id => {
          let keyword = $this.keywords.find(k => k.id === parseInt(key_id));
          if (keyword) {
            keyword.last_parser = response.keys[key_id]
            $this.settings.keys.push(keyword)
          }
        })
      })
    },
    restartParser() {
      if (!this.config.country && !this.config.key.length) {
        this.$notify({
          type: "error",
          text: "Выберите страну или запрос"
        });
      } else {
        let data = {country_id: this.config.country?.s_id, engines: this.config.engine, keys: this.config.key}
        ProjectApi.start_search(this.$route.params.id, data).then((response) => {
          this.$modal('hide', 'restart_settings');
          this.$notify(response);
          this.getProjectState()
        });
      }

    },
    bindLinks() {
      ProjectApi.bind_link(this.$route.params.id).then(() => this.$notify({
            type: "success",
            text: "Задача запущена"
          })
      );
    },
    contentSearch() {
      ProjectApi.content_search(this.$route.params.id).then(() =>
          this.$notify({
            type: "success",
            text: "Задача запущена"
          })
      );
    },
    changeAuditDate() {
      if (!this.date) {
        this.$notify({
          type: "error",
          text: "Выберите дату"
        });
      } else {
        ProjectApi.change_audit_date(this.$route.params.id, {date: this.date}).then((response) => {
          this.$notify(response);
          this.$modal('hide', 'change_date')
        });
      }

    },

  }
}
</script>

<style scoped>
.buttons {
  display: flex;
  flex-wrap: wrap;
  gap: 5px;
}

label {
  position: relative;
}

.date {
  position: absolute;
  right: 11px;
  top: 0;
  left: auto;
  font-size: 11px;
  color: #666;
}
</style>
