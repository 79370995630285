<template>
  <main class="dashboard links">
    <div class="mb-4 row">
      <h2 class="col">Так же спрашивают: {{ project.name }}</h2>

    </div>
    <div class="mb-5">
      <div class="d-flex filters">
        <MultiSelect
            v-if="keywords.length"
            v-model="filter.keyword"
            :options="keywords"
            :multi="true"
            :only_value="true"
            name="Запроc">
        </MultiSelect>
        <CountryChoice v-if="project.countries" v-bind:active_country.sync="active_country"
                       v-bind:project_id="$route.params.id"
                       v-bind:countries="project.countries"
                       v-bind:changeCountry="changeCountry"></CountryChoice>
        <button type="button" v-for="eng in $ENGINES"
                @click="engine = eng"
                :class="'btn btn-sm no-wrap ' + (engine===eng?'btn-dark':'btn-outline-dark')">
          <i :class="eng"></i> {{ eng }}
        </button>

        <date-picker
            v-model="filter.date_start"
            locale="ru"
            :clearable="false"
            format="dd.MM.yyyy"
            model-type="yyyy-MM-dd"
            auto-apply
            :allowed-dates="allowedDates"
            :enable-time-picker="false"></date-picker>

        <date-picker
            v-model="filter.date_end"
            locale="ru"
            :clearable="false"
            format="dd.MM.yyyy"
            model-type="yyyy-MM-dd"
            auto-apply
            :allowed-dates="allowedDates"
            :enable-time-picker="false"></date-picker>

        <MultiSelect
            v-if="also_select.length"
            v-model="filter.also_ask"
            :options="also_select"
            :only_value="true"
            :multi="true"
            name="Уникальный запрос"></MultiSelect>

        <button class="btn btn-sm btn-outline-dark" @click="getAlso">Сформировать</button>
        <button title="Сбросить все фильтры" @click="refreshFilters" class="btn btn-sm btn-danger">
          <i class="bi bi-x"></i>
        </button>

      </div>


    </div>

    <div class="card-body">
      <div class="table-responsive d-flex">
        <table class="table">
          <thead>
          <tr>
            <th scope="col" class="no-wrap">
              <MultiSelect
                  v-model="table_filter.keyword"
                  v-bind:options="table_keywords"
                  :only_value="true"
                  v-bind:multi="true"
                  name="Запрос"></MultiSelect>
            </th>
            <th scope="col" class="no-wrap">
              <MultiSelect
                  v-model="table_filter.also_ask"
                  :options="table_also"
                  :only_value="true"
                  :multi="true"
                  name="Похожий запрос"></MultiSelect>
            </th>
            <th scope="col" class="no-wrap">
              <MultiSelect
                  v-model="table_filter.tone"
                  :options="table_tones"
                  :only_value="true"
                  :multi="true"
                  name="Тональность"></MultiSelect>
            </th>
            <th scope="col" :colspan="dates.length">
              {{ table_data.length }} из {{ count }}
            </th>
          </tr>
          <tr class="sticky-top">
            <th scope="col" class="no-wrap">Запрос</th>
            <th scope="col">Похожий запрос</th>
            <th scope="col" class="no-wrap">Тональность</th>
            <th scope="col" class="text-center no-wrap" v-for="date in dates">{{ date }}</th>
          </tr>
          </thead>
          <tbody>
          <tr v-for="rel in table_data">
            <td class="link">
              {{ rel.name }}
            </td>
            <td>
              <span :style="getClass(rel.tone)">{{ rel.text }}</span>
            </td>
            <td>
              <ul class="dropdown list">
                <template v-if="$store.getters.permission.indexOf('element_edit') + 1">
                  <template v-for="(tone, key) in $TONES">
                    <li v-if="!tone.no_filter"
                        v-on:click="rel.tone = key; changeTone(rel);"
                        :class="`dropdown-item ${(rel.tone === key)?`active bg-${key}`:''}`">
                      {{ tone.name }}
                    </li>
                  </template>
                </template>
              </ul>
            </td>
            <td v-for="date in rel.dates">
              {{ date.place }}
            </td>

          </tr>

          </tbody>
        </table>


      </div>
    </div>
  </main>
</template>

<script>
import {AlsoAskApi} from "@/api/alsoask";
import {ProjectApi} from '@/api/project';
import SmallReport from '@/components/SmallReport';
import moment from "moment";


export default {
  name: 'ProjectAlsoAsk',
  data() {
    return {
      project: {id: ''},
      also_ask: [],
      order_by: '',
      count: 0,
      table_filter: {
        keyword: [], also_ask: [], tone: []
      },
      filter: {
        date_start: null,
        date_end: null, keyword: [], also_ask: []
      },
      engine: 'yandex',
      keywords: [],
      dates: [],
      active_country: {code: null, yandex: true, google: true},
      allowedDates: []
    }
  },
  components: {
    SmallReport
  },
  computed: {
    table_tones: function () {
      let data = Object.keys(this.$TONES).filter(x => !this.$TONES[x].no_filter).map(x => {
        return {id: x, name: this.$TONES[x].name}
      })
      data.push({id: null, name: 'Неразмечена'})
      return data
    },
    table_keywords() {
      return this.also_ask.map((e) => {
        return {id: e.keyword_id, name: e.keyword_name}
      }).filter((value, index, self) =>
              index === self.findIndex((t) => (
                  t.id === value.id
              ))
      );
    },
    table_also() {
      return this.also_ask.filter((x) => x.engine === this.engine).map((x) => {
        return {id: x.also_ask_id, name: x.text, tone: x.tone}
      }).filter((value, index, self) =>
              index === self.findIndex((t) => (
                  t.id === value.id
              ))
      );
    },
    also_select() {
      let uniq = [], keys = this.keywords
      if (this.filter.keyword.length) {
        keys = this.keywords.filter(key => this.filter.keyword.includes(key.id));
      }
      if (keys) {
        keys.forEach(e => uniq = uniq.concat(e.also_ask))
        uniq = Array.from(new Set(uniq.map(x => {
          return JSON.stringify({id: x[0], name: x[1]})
        })), JSON.parse)
      }
      return uniq.filter((value, index, self) =>
              index === self.findIndex((t) => (
                  t.id === value.id
              ))
      );
    },
    table_data() {
      let data = [], $this = this;
      this.table_keywords.forEach((key) => {
        $this.table_also.forEach(uniq => {
          let filter_key = this.also_ask.filter(x =>
              x.keyword_id === key.id &&
              x.engine === $this.engine &&
              x.also_ask_id === uniq.id);
          if (filter_key.length) {
            let data_key = JSON.parse(JSON.stringify(key));
            data_key.also_ask_id = uniq.id
            data_key.text = uniq.name
            data_key.tone = uniq.tone
            data_key.dates = []
            this.dates.forEach(date => {
              let filter_date = filter_key.find(x => x.date_create === date);
              data_key.dates.push({date: date, place: filter_date ? filter_date.position : "-"})
            })
            data.push(data_key)
          }
        })

      })
      this.count = data.length;
      if ($this.table_filter.keyword.length) {
        data = data.filter(key => $this.table_filter.keyword.includes(key.id));
      }
      if ($this.table_filter.also_ask.length) {
        data = data.filter(key => $this.table_filter.also_ask.includes(key.also_ask_id));
      }
      if ($this.table_filter.tone.length) {
        data = data.filter(key => $this.table_filter.tone.includes(key.tone));
      }
      return data
    }
  },
  mounted: function () {
    this.getProject()
  },
  methods: {
    changeCountry: function (country) {
      if (this.active_country.code !== country.code) {
        this.active_country = country;
        this.getFilterAlso();
      }
    },
    refreshFilters() {
      this.filter = {
        date_start: new Date(new Date().setDate(new Date().getDate() - 43)),
        date_end: new Date(), keyword: [], also_ask: []
      }
    },
    getProject: function () {
      let $this = this;
      ProjectApi.get_simple($this.$route.params.id).then(response => {
        $this.project = response;
        $this.active_country = $this.project.active_country_code;
        if (!this.filter.date_end) {
          let date = moment($this.project.last_parser, "DD/MM/YYYY")
          $this.filter.date_end = date.format("YYYY-MM-DD");
          $this.filter.date_start = date.add(-14, "days").format("YYYY-MM-DD");
        }
        this.getFilterAlso()
      });
    },
    getFilterAlso: function () {
      let $this = this;
      AlsoAskApi.get_filters($this.$route.params.id, $this.active_country.code).then(response => {
        $this.keywords = response.keywords;
        $this.allowedDates = response.dates;
        $this.getAlso()
      });
    },
    getAlso: function () {
      let $this = this, filter = structuredClone(this.filter);
      filter.country_code = $this.active_country.code;
      filter.project = $this.$route.params.id;
      let f_string = new URLSearchParams(filter).toString();
      AlsoAskApi.list(f_string).then(response => {
        $this.also_ask = response.results;
        $this.dates = [...new Set($this.also_ask.map(x=>x.date_create))];
      })
    },

    getClass: function (tone) {
      let COLORS = this.$COLORS;
      return `color: ${COLORS[tone] !== undefined ? COLORS[tone] : '#000000'}`;
    },

    changeTone: function (also_ask) {
      let $this = this;
      this.also_ask.filter(x => x.also_ask_id === also_ask.also_ask_id).forEach((x) => {
        x.tone = also_ask.tone
      });
      AlsoAskApi.update_tone(also_ask).then(() => {
        $this.$notify({
          group: 'alert',
          type: 'success ',
          text: 'Данные обновлены'
        });
      })
    },
  }
}
</script>
