<template>
  <main class="projects" v-if="project.id">
    <h3>Изображения: {{ project.name }}
    </h3>
    <div class="col justify-content-start align-items-baseline d-flex mb-2">

      <router-link v-if="$store.getters.permission.indexOf('project_edit')+1" type="button"
                   :to="`/projects/${$route.params.id}/edit/`"
                   class="btn btn-sm btn-dark">
        Редактировать
      </router-link>

      <button type="button" v-if="$store.getters.permission.indexOf('full_research') + 1"
              class="btn  btn-outline-primary ms-2 btn-sm" @click="getSearchResult()">Запустить
        парсер изображений
      </button>
      <router-link type="button" class="btn  btn-outline-primary ms-2 btn-sm"
                   :to="`/projects/${$route.params.id}/uniq_images/?country_code=${active_country.code}`">Уникальные
        изображения
      </router-link>
    </div>
    <ProjectInfo :project.sync="project"></ProjectInfo>
    <div class="d-flex justify-content-start align-items-baseline">
      <h4 class="no-wrap me-2">Сводные данные </h4>
      <date-picker v-if="active_date"
                   locale="ru"
                   :clearable="false"
                   v-model="active_date"
                   format="dd.MM.yyyy"
                   model-type="yyyy-MM-dd"
                   auto-apply
                   :allowed-dates="allowedDates"
                   :enable-time-picker="false">
      </date-picker>
    </div>
    <detail_info v-if="active_date"
                 v-bind:project="project"
                 v-bind:date="active_date"
                 v-bind:funcAll="getFull"
                 v-bind:state="state"
                 v-bind:images="true"
                 v-bind:full_info_show="full_info_show"
                 v-bind:active_country="active_country"
                 :allowedDates="allowedDates"
                 v-bind:report_types="{
                     'download_images_info': 'Отчёт по выдаче изображений ',
                     'summary_info_image': 'Отчёт изображений по ключевым словам',
                   }"
    ></detail_info>

    <div class="project-controls d-flex">
      <div class="controls d-flex">
        <div class="form-check form-switch pt-2">
          <input class="form-check-input" v-model="full_url" type="checkbox" role="switch" id="full_url" checked>
          <label class="form-check-label" for="full_url">Полные ссылки</label>
        </div>

        <div class="form-check form-switch ms-5 pt-2">
          <input class="form-check-input" v-model="link_cross" type="checkbox" role="switch" id="link_cross">
          <label class="form-check-label" for="link_cross">Пересечение с органической выдачей</label>
        </div>
        <div class="form-check form-switch ms-5 pt-2">
          <input class="form-check-input" v-model="show_comment" type="checkbox" role="switch" id="show_comment">
          <label class="form-check-label" for="show_comment">Показывать комментарии</label>
        </div>
      </div>
      <div class="controls d-flex">
        <button v-for="top in $STATES" type="button" @click="state=top.id"
                :class="`btn btn-sm btn-outline-primary ${state===top.id?' active':''}`">
          {{ top.name }}
        </button>
        <date-picker v-if="active_date"
                     v-model="active_date"
                     locale="ru"
                     :clearable="false"
                     format="dd.MM.yyyy"
                     model-type="yyyy-MM-dd"
                     auto-apply
                     :allowed-dates="allowedDates"
                     :enable-time-picker="false">
        </date-picker>
        <CountryChoice
            v-bind:active_country.sync="project.active_country_code"
            v-bind:project_id="$route.params.id"
            v-bind:countries="project.countries"
            :changeCountry="changeCountry"
        >
        </CountryChoice>
      </div>
    </div>


    <div class="card-body border border-primary pt-0">


      <div class="card-body border border-primary pt-0">

        <div class="div" v-if="link_image!==null">
          <button class="btn btn-sm btn-outline-primary" @click="link_image=null;"><i
              class="bi bi-arrow-left-short me-1"></i>
            Назад
          </button>
          <div class="d-flex mt-3 mb-3">
            <div class="link bg-light col">
              <h2>
                <a :href="link_image.link_image" class="text-dark" target="_blank">{{ link_image.link_image }}

                </a>
              </h2>
              <a :href="link_image.link_result">Ссылка в выдаче</a> | <a :href="link_image.link_bucket">Ссылка в
              хранилище</a>
            </div>
          </div>
          <div class="link-detail">
            <div class="item">
              <div class="head">Тональность</div>
              <ul class="dropdown list">
                <template v-for=" (tone, key) in $TONES">
                  <li v-if="!tone.no_filter"
                      v-on:click="link_image.tone = key;
                            link_image.get_tone_display = tone.name; updateImage(link_image)"
                      :class="`dropdown-item ${link_image.tone === key?`active bg-${key}`:''}`">{{ tone.name }}
                  </li>
                </template>

              </ul>
            </div>
            <div class="item">
              <div class="head">Примечание</div>
              <div class="dropdown list">
                <textarea v-model="link_image.comment" cols="30" rows="10" class="w-100"></textarea>

                <button class="btn btn-sm btn-primary blue-bg" @click="updateImage(link_image)">Сохранить</button>
              </div>
            </div>

          </div>


          <div id="legend-container-image" class="legend"></div>
          <div class="chart" v-if="link_image.chart_data">

            <div :style="`height: 420px; width: ${link_image.chart_data?.labels?.length*25}px;`">
              <Line
                    ref="linkImageChart"
                    :options="chartOptions"
                    :data="link_image.chart_data"
                    :plugins="[htmlLegendPlugin]"
              />
            </div>
          </div>


          <div class="" v-if="!link_image.cross">
            <h4 style="text-align: center; background: #ccc" class="h4">Пересечение с органической выдачей</h4>
            <LinkDetail v-bind:link_id="link_image.link_obj_id" v-bind:date="active_date"
                        v-bind:country_code="active_country.code" v-bind:image="true"
                        v-bind:project_id="$route.params.id" v-bind:keywords="keywords"></LinkDetail>
          </div>

        </div>
        <div class="table-responsive d-flex position-relative search-results images"
             style="height: 80vh; overflow: scroll; align-items: start;" v-else>
          <table class="table sticky" v-if="keywords.length">
            <thead class="sticky-top table-head">
            <tr>
              <th scope="col" class="text-center no-wrap">#</th>
            </tr>
            </thead>
            <tbody>
            <tr class="text-center sticky-top table-sub-head">
              <td><i>-</i></td>
            </tr>
            <tr>
              <td :class="full_url?' full-url':''">
                <table class="table result" style="margin-top: 5px">
                  <tbody>
                  <tr :class="full_url?' full-url':''"
                      v-for='index in Math.max(...keywords.map(x=>Math.max(...[x["searches"]["yandex"].length, x["searches"]["google"].length])))'>
                    <td class="no-wrap link_info engine_info">
                      <div class="inline-block" style="border: 12px solid transparent; margin: -12px;">
                        <div>
                          <span>{{ index }}</span>
                        </div>
                      </div>
                    </td>
                  </tr>

                  </tbody>
                </table>

              </td>
            </tr>

            </tbody>
          </table>
          <table :class="`table${full_url?' full':''}`" v-for="(key, index) in keywords">
            <thead class="sticky-top table-head">
            <tr>
              <th scope="col" class="text-center no-wrap border-end" :colspan="!index?3:2">{{ key.name }}</th>
            </tr>
            </thead>

            <tbody>
            <tr class="text-center sticky-top table-sub-head">
              <td v-for="(engine, index) in engines"
                  :class="engines.length === index+1?'border-end':''">
                <i :class="engine"></i>
              </td>
            </tr>
            <tr>
              <template v-for="(engine, index) in engines">
                <td :style="engines.length === index+1?`border-right: 1px solid #ccc`:''">
                  <table class="table" style="margin-top: 10px;">
                    <tbody>
                    <tr v-for="link in key.searches[engine]">
                      <td>
                        <a :href="link.link_image" target="_blank" class="image"
                           :style="`background-image: url(${link.link_bucket||link.link_image})`">
                        </a>
                      </td>
                      <td :class="`no-wrap link_info engine_info${full_url?' full-url':''}`" :id="'link-'+link.id">
                          <span @click="link_image=link"
                                :class="`link${link_cross&&link.cross?' fw-bold':''}`"
                                :style="$func.getClass(link.tone, false)">
                          <popper hover>
                             <span slot="reference">
                              {{ full_url ? link.link : link.domain }}
                            </span>
                            <template #content>
                            <div class="popper text-start">
                              <span>{{ link.title }}</span><br>
                              <b>Изображение</b>:
                              <a :href="link.link_image" target="_blank">
                              {{ link.link_image }}
                              </a>
                              <br>
                              Тон: {{ link.get_tone_display }}
                              <br>
                              Комментарий: {{ link.comment }}
                              <br>
                              <b>Статья</b>: <a :href="link.link" target="_blank">
                              {{ link.link }}
                              </a>
                              <br>
                              <a :href="link.link_result" target="_blank">
                              <b>Ссылка в выдаче</b>
                              </a>
                              <br>

                              <template v-if="link.cross">
                                <b>Пересечение с органической выдачей</b><br>

                              Тон: {{ link.tone_name }}
                              <br>
                              Вид: {{ link.kind_type_name }}
                              <br>
                              Тип: {{ link.type_tone_name }}
                              <br>
                              Метод воздействия: {{ link.delete_type }}
                              <br>
                                 </template>


                            </div>
                            </template>

                          </popper>

                      </span>
                        <p v-if="link.comment && show_comment">Комментарий: {{ link.comment }}</p>
                      </td>

                    </tr>

                    </tbody>
                  </table>
                </td>
              </template>
            </tr>
            </tbody>
          </table>


        </div>

      </div>
    </div>
  </main>
</template>

<script>
import {LinkApi, ProjectApi} from '@/api/project'
import moment from 'moment';
import SmallReport from '@/components/SmallReport';
import detail_info from "@/views/projects/detail/includes/Serm.vue";
import LinkDetail from "@/views/projects/detail/includes/LinkDetail.vue";
import ProjectInfo from "@/components/ProjectInfo.vue";
import {PictureApi} from "@/api/pictures";
import {Line} from 'vue-chartjs'
import {
  Chart as ChartJS,
  Title,
  Tooltip,
  Legend,
  LineElement,
  CategoryScale,
  LinearScale,
  PointElement,
  Filler
} from 'chart.js'

ChartJS.register(Title, Tooltip, Legend, LineElement, CategoryScale, LinearScale, Filler, PointElement)
import htmlLegendPlugin from "@/components/legendPlugin";

ChartJS.defaults.plugins.legend.display = false;

export default {
  name: 'ProjectDetailImages',
  components: {
    Line,
    ProjectInfo,
    LinkDetail,
    detail_info,
    SmallReport
  },
  data() {
    return {
      htmlLegendPlugin: htmlLegendPlugin,
      project: {},
      active_date: null,
      old_active_date: null,
      old_active_country: null,
      edit_comment: false,
      full_info: false,
      full_info_show: false,
      state: 20,
      full_url: false,
      link_cross: false,
      show_comment: false,
      keywords: [],
      tops: [10, 20, 50, 100],
      link_image: null,
      allowedDates: [],
      chartOptions: null,
      scroll_link: null

    }
  },
  computed: {
    active_country() {
      return this.project?.active_country_code
    },
    date_query() {
      if (this.active_date?.includes("/")) return moment(this.active_date, 'DD/MM/YYYY').format("YYYY-MM-DD");
      return this.active_date;
    },
    engines() {
      return this.$ENGINES.filter(x => this.active_country ? this.active_country[x] : false)
    }
  },
  watch: {
    active_date: function (val) {
      if (this.old_active_date !== null) this.getProject();
      this.old_active_date = val;
    },
    state: function (val) {
      this.getKeywords(this.project.id);
    },
    link_image: function (val, old) {
      let $this = this;
      if (!val && old && this.scroll_link) {
        setTimeout(() => {
          const element = document.getElementById("link-" + this.scroll_link);
          element.scrollIntoViewIfNeeded();
        }, 300)

      }
      if (val && !this.link_image.chart_data && this.link_image.img_obj_id) {
        this.scroll_link = this.link_image.id;
        PictureApi.chart_data(this.link_image.img_obj_id).then((response) => {
          let datacollection = {
            labels: response.labels,
            datasets: response.data
          }
          const COLORS = {
            "Yandex": "#FE422D",
            "Google": "#3780FF"
          }
          if (datacollection.labels) {
            let new_data = [];
            for (let i in datacollection.datasets) {
              let d = datacollection.datasets[i];
              let n_d = {
                label: d.label,
                borderColor: COLORS[d.label],
                backgroundColor: COLORS[d.label],
                data: d.data,
                lineTension: 0,
                borderWidth: 2,
                pointRadius: 5,
                fill: false
              };
              new_data.push(n_d);
            }

            this.chartOptions = {
              responsive: true,
              maintainAspectRatio: false,
              aspectRatio: 1,
              scales: {
                x:
                    {
                      grid: {
                        display: false,
                      },
                      ticks: {
                        autoSkip: false,
                        maxRotation: 90,
                        minRotation: 90,
                        font: {
                          size: 12,
                        }
                      },
                    },
                y:
                    {
                      display: false,
                      reverse: true,
                      maxTicksLimit: Math.max(...datacollection.datasets.map(x => x.data).flat().filter(x => x <= 100)) + 10,
                      suggestedMax: Math.max(...datacollection.datasets.map(x => x.data).flat().filter(x => x <= 100)) + 10,
                      max: Math.max(...datacollection.datasets.map(x => x.data).flat().filter(x => x <= 100)) + 10,
                    },

              },

              plugins: {
                htmlLegend: {
                  containerID: 'legend-container-image',
                },
                legend: {
                  display: false,
                }
              }

            }

            $this.link_image.chart_data = {
              labels: datacollection.labels,
              datasets: new_data,
            };
          }

        })
      }
    },
    active_country: function (val) {
      if (this.old_active_country !== null && this.old_active_country?.id !== val?.id) this.getProject();
      this.old_active_country = val;
      this.full_info = false;
      this.full_info_show = false;
    },
  },
  mounted: function () {
    let $this = this;
    ProjectApi.get_simple(this.$route.params.id).then(response => {
      $this.project = response
      $this.getProject();
    });
  },
  methods: {
    changeCountry: function (country) {
      if (this.active_country.code !== country.code) {
        this.project.active_country_code = country;
      }
    },
    getProject: function () {
      let $this = this;
      ProjectApi.get_images_info($this.$route.params.id, this.date_query, $this.active_country.code || 'RU').then(response => {
        $this.project = {...$this.project, ...response.stats};
        if (!$this.active_date) {
          $this.active_date = moment(response.stats.date, 'YYYY-MM-DD').format('YYYY-MM-DD');
        }
        if (!$this.allowedDates.length) {
          ProjectApi.active_images_dates(this.$route.params.id).then(response => $this.allowedDates = response);
        }
        $this.getKeywords($this.project.id)
      });
    },
    getKeywords: function (id) {
      let $this = this;
      ProjectApi.image_keywords(id, $this.state, this.date_query, $this.active_country.code || 'RU').then(response => {
        $this.keywords = response;
      })
    },
    getFull: function () {
      let $this = this;
      if (!$this.full_info) {
        ProjectApi.get_full($this.$route.params.id, $this.date_query, $this.active_country.code || 'RU').then(response => {
          $this.full_info = true;
          $this.full_info_show = true;
          $this.project = {
            ...$this.project,
            ...response
          };
        });
      } else $this.full_info_show = !$this.full_info_show;
    },
    updateImage: function (link) {
      let $this = this;
      LinkApi.update_image(link).then(response => {
        $this.$notify({
          type: 'success ',
          text: 'Данные обновлены'
        });
      })
    },
    getSearchResult() {
      let $this = this;
      ProjectApi.start_search_image($this.$route.params.id).then(response => {
        $this.$notify({
          type: 'success ',
          text: 'Задача на сбор изображений запущена'
        });
      });

    },
  }
}
</script>
<style scoped lang="scss">
.num {
  height: 152px !important;
}
.chart {
  overflow-x: scroll;
}

.link-detail {
  display: flex;
  gap: 20px;
  margin-top: 30px;

  .group {
    margin-top: -30px;
    margin-right: -20px;
  }

  .sub-name {
    border-radius: 13px 13px 0 0;
    border: 1px solid rgba(244, 244, 244, 1);
    height: 30px;
    font-size: 14px;
    white-space: nowrap;
    display: flex;
    padding: 5px 12px;
  }

  .items {
    display: flex;
    flex-direction: row;
    width: 100%;

    .item {
      width: 100%;
      text-align: center;

      .head {
        margin-right: auto;
        width: 100%;
      }
    }
  }

  .item {
    display: flex;
    flex-direction: column;
    gap: 20px;

    .head {
      margin-right: -20px;
      background: rgba(244, 244, 244, 1);
      font-weight: 600;
      font-size: 14px;
      padding: 5px 0;
      height: 30px;
    }
  }
}

.search-results.images {
  .engine_info {
    height: 152px !important;
    vertical-align: top !important;
    padding-top: 5px;
  }
}

.image {

  display: block;
  width: 150px;
  height: 150px;
  background-repeat: no-repeat;
  background-size: contain;
  background-position: top left;
}

.link_info {
  span[slot="reference"] {
    cursor: pointer;
  }

  p {
    white-space: normal;
  }
}
</style>
