<template>
  <main class="projects links" v-if="project.id">
    <h3>Видео: {{ project.name }}
    </h3>
    <div class="col justify-content-start align-items-baseline d-flex mb-2">

      <router-link v-if="$store.getters.permission.indexOf('project_edit')+1" type="button"
                   :to="`/projects/${$route.params.id}/edit/`"
                   class="btn btn-sm btn-dark">
        Редактировать
      </router-link>



    </div>


    <div class="card mt-3">
      <div class="card-body">

        <div class="project-controls d-flex mb-3">
          <div class="controls d-flex">
            <date-picker
                v-model="active_date"
                locale="ru"
                :clearable="false"
                format="dd.MM.yyyy"
                model-type="yyyy-MM-dd"
                auto-apply
                :allowed-dates="allowedDates"
                :enable-time-picker="false"
            ></date-picker>
            <CountryChoice v-if="project.countries" v-bind:active_country.sync="active_country"
                           v-bind:project_id="$route.params.id"
                           v-bind:countries="project.countries"
                           v-bind:changeCountry="changeCountry"></CountryChoice>
            <MultiSelect
                v-model="state"
                :options="$STATES"
                :only_value="true"
                :multi="false"
                name="Топ"
            ></MultiSelect>
            <MultiSelect
                v-if="keywords.length"
                v-model="keyword"
                :options="keywords"
                :only_value="true"
                :multi="true"
                name="Запрос"
            ></MultiSelect>
            <button type="button" v-for="eng in engines"
                    @click="engine = eng"
                    :class="'btn btn-sm no-wrap ' + (engine === eng?'btn-dark':'btn-outline-dark')">
              <i :class="eng"></i> {{ eng }}
            </button>
          </div>

        </div>
        <div class="card-body border border-primary pt-0">

          <div class="table-responsive d-flex position-relative">
            <table class="table">
              <thead>
              <tr class="sticky-top">
                <th scope="col" class="no-wrap">Видео</th>
                <th scope="col" class="no-wrap">Информация</th>
                <th scope="col" class="no-wrap">Позиция</th>
                <th scope="col" class="no-wrap">Страница</th>
                <th scope="col" class="no-wrap">Запрос</th>
                <th scope="col" class="no-wrap">Тон</th>
              </tr>
              </thead>
              <tbody>
              <tr v-if="empty_text">
                <td colspan="6" class="text-center"><h5>{{ empty_text }}</h5></td>
              </tr>
              <tr v-for="(result, index) in filter_results">
                <td class="engine_info">
                  <a :href="result.url" target="_blank" class="video-link">
                    <i class="bi bi-play-circle-fill"></i>
                    <img :src="result.image" width="250" alt="">
                  </a>
                </td>
                <td class="engine_info">
                  <a href="">{{ result.url }}</a>
                  <h5>{{ result.title }}</h5>
                  <p>{{ result.description }}</p>
                </td>
                <td class="engine_info">{{ result.position }}</td>
                <td class="engine_info">{{ result.page }}</td>
                <td>{{ result.keyword_name }}</td>

                <td>
                  <ul class="dropdown list">
                    <template v-if="result.video_id">
                      <template v-for="(tone, key) in $TONES">
                        <li v-if="!tone.no_filter"
                            v-on:click="result.tone = key; changeTone(result);"
                            :class="`dropdown-item ${(tone.synonym.indexOf(result.tone)+1)?`active bg-${key}`:''}`">
                          {{ tone.name }}
                        </li>
                      </template>
                    </template>
                    <template v-else>
                      <li :class="`dropdown-item active bg-${result.tone}`">
                        {{ result.get_tone_display || "Неразмечена" }}
                      </li>
                    </template>
                  </ul>
                </td>


              </tr>

              </tbody>
            </table>


          </div>
        </div>

      </div>
    </div>


  </main>
</template>

<script>
import SmallReport from '@/components/SmallReport';
import ProjectInfo from "@/components/ProjectInfo.vue";
import detail_info from "@/views/projects/detail/includes/Serm.vue";
import moment from "moment/moment";
import {LinkApi, ProjectApi} from "@/api/project";
import {VideoApi} from "@/api/video";
import {PictureApi} from "@/api/pictures";
import MultiSelect from "@/plugins/Select.vue";


export default {
  name: 'Video',
  components: {
    MultiSelect,
    detail_info,
    ProjectInfo,
    SmallReport
  },
  data() {
    return {
      active_date: null,
      old_active_date: null,
      state: 10,
      project: {},
      keywords: [],
      results: [],
      old_active_country: null,
      active_country: {code: null, yandex: true, google: true},
      keyword: [],
      allowedDates: [],
      engine: "yandex",
      empty_text: ""
    }
  },
  computed: {
    filter_results() {
      let results = this.results
      if (this.engine) results = results.filter(x => x.engine === this.engine)
      if (this.state) results = results.filter(x => x.position <= this.state)
      if (this.keyword.length) results = results.filter(x => this.keyword.indexOf(x.keyword_id) + 1)
      this.empty_text = !results.length ? 'Выдача по заданным параметрам отсутствует' : '';
      return results;
    },
    engines() {
      let engines = this.$ENGINES.filter(x => this.active_country ? this.active_country[x] : true);
      this.engine = engines[0]
      return engines
    }
  },
  watch: {

    active_date: function (val) {
      if (this.old_active_date !== null) this.getData();
      this.old_active_date = val;
    }
  },
  mounted: function () {
    let $this = this;
    ProjectApi.get_simple($this.$route.params.id).then(response => {
      $this.project = response;
      $this.active_country = $this.project.active_country_code;
      if (!$this.active_date) {
        $this.active_date = moment($this.project.last_parser, "DD/MM/YYYY").format("YYYY-MM-DD");
      }
      this.getData();
    });
    ProjectApi.get_avail_date_calendar(this.$route.params.id).then(response => this.allowedDates = response)

  },
  methods: {
    changeCountry: function (country) {

      if (this.active_country.code !== country.code) {

        this.active_country = country;
        this.getData();
      }
    },
    getData() {
      this.getKeywords();
      this.getResults()
    },

    getKeywords: function () {
      let $this = this;
      PictureApi.keywords($this.$route.params.id, $this.active_country.code).then(response => {
        $this.keywords = response;

      })
    },
    getResults: function () {
      let $this = this;
      let f_string = new URLSearchParams({
        project: $this.$route.params.id,
        country_code: $this.active_country.code,
        date: this.active_date
      }).toString();
      VideoApi.list(f_string).then(response => {
        $this.results = response.results;
        for (const engine of $this.$ENGINES) {
          if (this.results.filter(x => x.engine === engine).length) {
            $this.engine = engine;
            break;
          }
        }
      })
    },

    changeTone: function (result) {
      let $this = this;
      $this.results.forEach(function (e) {
        if (e.video_id === result.video_id) {
          e.tone = result.tone;
        }
      })
      VideoApi.update({tone: result.tone, id: result.video_id,}).then(() => {
        $this.$notify({
          type: 'success ',
          text: 'Данные обновлены'
        });
      })
    }

  }
}

</script>

<style scoped lang="scss">
.video-link {
  position: relative;

  i {
    position: absolute;
    color: #fff;
    margin-left: auto;
    margin-right: auto;
    left: 0;
    right: 0;
    top: 50%;
    transform: translateY(-50%);
    text-align: center;
    font-size: 57px;
    opacity: 0.7;
  }
}
</style>

