<template>
  <div class="stats">
    <div class="graph" v-if="positions?.stats?.donut">
      <Doughnut :data="positions?.stats?.donut" :options="{
      responsive: true,
      maintainAspectRatio: false,
    }
    "/>
    </div>
    <div class="stat" v-if="positions?.stats">
      <div class="place up">
        <span><b>&uarr;</b>{{ positions?.stats?.up?.count }}</span>{{ positions?.stats?.up?.percent }}%
      </div>
      <div class="place no-change">
        <span><b>=</b>{{ positions?.stats?.no_change?.count }}</span>{{ positions?.stats?.no_change?.percent }}%
      </div>
      <div class="place down">
        <span><b>&darr;</b>{{ positions?.stats?.down?.count }}</span>{{ positions?.stats?.down?.percent }}%
      </div>
    </div>
    <div class="props" v-if="positions?.results">
      <template v-for="value in positions?.results">
        <div class="prop">
          <div class="name" :style="{background: value.name[2]}">
            {{ value.name[0] }} - {{ value.name[1] }}
          </div>
          <div class="data">
            <span>{{ value.percent }}</span>
            <span>{{ value.len }}</span>
            <span :class="'text-'+(value.change>=0?'success':'danger')">
                    {{ value.change }}</span>

          </div>

        </div>
      </template>
    </div>
  </div>
</template>

<script>
import {DomainApi} from "@/api/domains";
import {Doughnut} from "vue-chartjs";

import {Chart as ChartJS, ArcElement} from 'chart.js'

ChartJS.register(ArcElement)

ChartJS.defaults.plugins.legend.display = false;

export default {
  name: 'DomainSmall',
  components: {Doughnut},
  props: {
    domain: {type: Object},
    dates: {type: Array},
    keywords: {type: Array},
    country: {type: String}
  },

  data() {
    return {
      positions: {},
      period_params: [
        [101, "", '#FCC53B'],
        [51, 100, '#BCC8C8'],
        [21, 50, '#94DA90'],
        [11, 20, '#1ABC9C'],
        [4, 10, '#21936C'],
        [1, 3, '#3198DD']
      ]
    }
  },
  mounted: function () {
    const $this = this;
    let filters = {
      country_code: this.country,
      dates: this.dates
    }

    let f_string = new URLSearchParams(filters).toString();
    DomainApi.get_stat_data(this.domain.id, f_string).then(resp => {
      let positions = resp;

      let dates = this.dates, result = [], stats = {}, datas = [], date_end = dates[0],
          date_start = dates[dates.length - 1];


      this.keywords.forEach((key, k_index) => {
        let keyword = {
          id: key.id
        }
        dates.forEach((date, index) => {
          let pos = positions.find(x => x.date_create__date === date && x.result__keyword_id === key.id)
          keyword[date] = {place: pos?.place || 101, change: null}
          if (index) {
            let prev = keyword[dates[index - 1]].place;
            if (!pos && prev) keyword[date].change = false;
            else if (pos && !prev) keyword[date].change = '+';
            else keyword[date].change = (prev === 101) ? 0 : prev - keyword[date].place
          }
        })
        datas.push(keyword)
      })

      this.period_params.forEach((param) => {
        let data = datas.filter(x => {
          return (param[1] ? param[1] : 102) >= x[date_start].place && x[date_start].place >= param[0]
        });
        let total = data.map(x => (x[date_end].place < 101 && x[date_start].place < 101) ? x[date_end].place - x[date_start].place : 0).reduce((a, c) => {
          return a + c
        }, 0)
        result.push({
          name: param,
          percent: Math.round(data.length * (100 / datas.length)) + '%',
          len: data.length,
          change: total
        })
      })
      this.positions.results = result;

      if (dates.length && datas.length) {
        let up = datas.filter(x => x[date_start].change > 0)
        let no_change = datas.filter(x => x[date_start].change === 0)
        let down = datas.filter(x => x[date_start].change < 0)
        let all = datas.length;
        stats.up = {count: up.length, percent: Math.round(up.length * (100 / all))}
        stats.no_change = {count: no_change.length, percent: Math.round(no_change.length * (100 / all))}
        stats.down = {count: down.length, percent: Math.round(down.length * (100 / all))}
        stats.donut = {
          labels: [], datasets: [{
            backgroundColor: ["#36b556", "#f48b1b", "#f12e27"],
            data: [stats.up.count, stats.no_change.count, stats.down.count]
          }]
        }
        this.positions.stats = stats

      }

    });
  },
  methods: {}
}

</script>
<style scoped lang="scss">

.stats {
  //background: #eee;
  padding: 10px;
  display: flex;
  gap: 20px;

  .graph {
    width: 80px;
    height: 100px;
    padding: 10px;
    box-shadow: 0 0 13.6px 4px #00000012;
    background: #fff;
    border-radius: 5px;
  }

  .stat {
    display: flex;
    flex-direction: column;
    gap: 5px;
    font-size: 12px;
    padding: 10px;
    box-shadow: 0 0 13.6px 4px #00000012;
    background: #fff;
    border-radius: 5px;
    justify-content: center;

    .place {
      display: flex;
      gap: 30px;
      border-bottom: 1px solid #ccc;
      color: #8491a4;
      padding-bottom: 3px;
      align-items: baseline;

      span {
        display: flex;
        gap: 5px;

        b {
          display: block;
          height: 15px;
          width: 15px;
          background: #ccc;
          color: #fff1f0;
          text-align: center;
          border-radius: 15px;
          font-size: 12px;
          line-height: 19px;
        }
      }

      &.up {
        span {
          color: #36b556;

          b {
            background: #36b556;
          }
        }
      }

      &.no-change {
        span {
          color: #f48b1b;

          b {
            background: #f48b1b;
          }
        }
      }

      &.down {
        span {
          color: #f12e27;

          b {
            background: #f12e27;
          }
        }
      }

    }
  }

  .props {
    display: flex;
    gap: 10px;

    .prop {
      padding: 5px;
      box-shadow: 0 0 13.6px 4px #00000012;
      background: #fff;
      border-radius: 5px;
      width: 65px;
      display: flex;
      flex-direction: column;
      gap: 5px;

      .name {
        text-align: center;
        color: #fff1f0;
        font-weight: bold;
        border-radius: 5px;
        padding: 3px;
        font-size: 13px;

      }

      .data {
        display: flex;
        flex-direction: column;
        gap: 3px;
        font-size: 12px;
        text-align: center;

        span {
          display: block;
          width: 100%;
          text-align: center;

          &:first-child {
            color: #999;
            border-bottom: 1px solid #ccc;

          }
        }

      }
    }
  }
}

</style>
