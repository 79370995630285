import {createRouter, createWebHistory} from 'vue-router'

import serviceRouters from "./services"
import projectRouters from "./projects"
import textRouters from "@/text/router/index"
import authyRouters from "@/authy/router/index"
import PfLinkStat from '@/views/planfix/LinkStat.vue'
import TargetLink from '@/views/planfix/TargetLink.vue'
import PageNotFound from '@/views/404.vue'
import store from '@/store/index'
import InDev from "@/views/InDev.vue"
import Front from '@/views/Front.vue'


const router = new createRouter({
    history: createWebHistory(),
    base: '/',
    routes: [
        {
            path: '/',
            name: 'base',
            component: Front,
            meta: {
                title: 'ReputationAnalytics',
                requiresAuth: true
            }
        },
        ...serviceRouters,
        ...projectRouters,
        ...textRouters,
        ...authyRouters,


        {
            path: '/links/statistics',
            name: 'PfLinkStat',
            component: PfLinkStat,
            meta: {
                // notAuth: true
            }
        },

        {
            path: '/target_link/:link_id',
            name: 'TargetLink',
            component: TargetLink,
            meta: {
                requiresAuth: true
            }
        },
        {
            path: '/development',
            name: 'dev',
            component: InDev,
            meta: {
                // notAuth: true
            }
        },

        {
            path: "/404", name: '404',
            component: PageNotFound,
            meta: {
                title: 'Страница не найдена',
                notAuth: true
            }
        },
        // {
        //     path: "/:catchAll(.*)", name: '404',
        //     redirect: 'PageNotFound',
        // },
        {
            path: "/:pathMatch(.*)",
            name: 'PageNotFound',
            component: PageNotFound,
            notAuth: true
        }
    ],
    linkActiveClass: "active",
    linkExactActiveClass: "exact-active",
});

export default router
const DEFAULT_TITLE = 'ReputationAnalytics';
router.beforeEach((to, from, next) => {
    document.title = to.meta.title || DEFAULT_TITLE;
    next();
});
import {cancelSource} from "@/api/common"

router.beforeEach((to, from, next) => {
    // cancelSource.cancel('Your cancellation message');
    store.commit('set_route', to);
    store.dispatch('init', to.params.id).then(() => {

        if (to.matched.some(record => record.meta.requiresAuth)) {
            if (store.getters.isLoggedIn) {
                if (to.matched.some(record => record.meta.base_name !== "unique_text") && location.host === process.env.VUE_APP_TEXT_API) {
                    next({name: 'unique_text'})
                }
                if (to.matched.some(record => record.meta.base_name !== "authy") && location.host.includes("authy.")) {
                    next({name: 'authy'})
                }
                // if (store.getters.permission.indexOf(to.name) + 1
                //     || !to.matched.some(record => record.meta.hasPerm)) {
                    next();
                // } else next('404')
                return
            }
            next({
                name: 'login',
                query: {
                    next: to.fullPath
                },
            });
        } else if (to.matched.some(record => record.meta.notAuth)) {
            if (store.getters.isLoggedIn) {
                next({
                    name: 'base',
                    query: {},
                });
            } else {
                next();
            }

        } else {
            next()
        }
    }, error => {
        console.log(error)
    }).catch((e) => console.error(e))

});
