<template>
  <div :class="'rounded knowledge-block border border-dark-gray'+(knowledge.popular?' popular':'')"
       @click="clickKnow">
    <div class="popular" v-if="knowledge.popular">Популярный результат</div>
    <div :class="'input i-'+knowledge.engine">
      <i class="bi bi-search"></i>
      <span>{{ knowledge.keyword_name }}</span>
    </div>
    <h4>{{ knowledge.title }}</h4>
    <p>{{ knowledge.description }}</p>
    <div class="block" v-for="block in knowledge.blocks">
      <div class="prop" v-if="block.title">
        <h5 v-html="block.title"></h5>
        <button v-if="knowledge.popular" @click="addProp(block, 'title')" class="btn rounded"><i
            :class="'bi bi-' + (block.selected_fields?.indexOf('title')+1?'check-circle':'circle')"></i></button>
      </div>
      <div class="prop" v-if="block.description">
        <p v-html="block.description"></p>
        <button v-if="knowledge.popular" @click="addProp(block, 'description')" class="btn rounded"><i
            :class="'bi bi-' + (block.selected_fields?.indexOf('description')+1?'check-circle':'circle')"></i></button>
      </div>
      <div class="prop" v-if="block.screenshot">
        <img :src="block.screenshot_path" alt="">
        <button v-if="knowledge.popular" class="btn rounded" @click="addProp(block, 'screenshot')"><i
            :class="'bi bi-' + (block.selected_fields?.indexOf('screenshot')+1?'check-circle':'circle')"></i></button>
      </div>

    </div>

  </div>

</template>

<script>
import {ProjectApi} from "@/api/project";
import {KnowledgeApi} from "@/api/knowledge";

export default {
  name: "KnowledgeSmall",
  props: {
    knowledge: Object,
    changeFunction: Function
  },

  data() {
    return {
      selected_fields: ["title", "description", "screenshot"]
    }
  },
  computed: {},
  watch: {},
  mounted: function () {
  },
  methods: {

    clickKnow() {
      if (!this.knowledge.popular) {
        this.changeFunction(this.knowledge.id);
        this.knowledge.popular = true;
        this.knowledge.blocks.forEach(x => {
          if (!x.selected_fields) x.selected_fields = [];
          if (!!x.title) x.selected_fields.push("title")
          if (!!x.description) x.selected_fields.push("description")
          if (!!x.screenshot) x.selected_fields.push("screenshot")
          x.selected_fields = [...new Set(x.selected_fields)]
        })
        this.setPopular();
      }

    },

    setPopular() {
      KnowledgeApi.update(this.knowledge.id, this.knowledge);
    },
    addProp(block, field) {

      if (!block.selected_fields) block.selected_fields = []
      let index = block.selected_fields?.findIndex(x => x === field)
      if (index < 0) block.selected_fields.push(field)
      else block.selected_fields.splice(index, 1);
      this.setPopular()
    },
    getScreen(path, engine) {
      ProjectApi.screen_to_png(this.$route.params.id, `path=${path}&engine=${engine}`).then((response) => {
        const blobUrl = URL.createObjectURL(response);
        window.open(blobUrl, '_blank');
      })
    },

  },
}
</script>
<style lang="scss" scoped>
.knowledge {
  display: flex;
  flex-wrap: wrap;
  gap: 30px;

  .knowledge-block {
    position: relative;
    flex-grow: 1;
    width: 30%;
    max-width: 450px;
    padding: 20px;
    border-radius: 20px !important;
    cursor: pointer;
    &.popular{
      .block{
        border-bottom: 1px solid #838282;
      }
    }

    .block {
      display: flex;
      flex-direction: column;
      gap: 5px;
      padding-bottom: 5px;

      .prop {
        display: flex;
        justify-content: space-between;
        width: 100%;
        gap: 5px;

        button {
          border: none !important;
          flex: 0 0 20px;
          width: 20px;
          padding: 0;
        }
      }
    }

    .input {
      padding: 5px 15px;
      border-radius: 10px;
      display: flex;
      gap: 10px;
      border: 2px solid rgba(252, 189, 6, 1);
      margin-bottom: 20px;
      height: 35px;
      align-items: center;
      font-size: 14px;
      font-weight: 500;

      &.i-google {
        border: none;
        border-radius: 20px;
        padding: 0 15px;
        box-shadow: 0 1px 10px 0 rgba(0, 0, 0, 0.15);
      }
    }

    .popular {
      padding: 2px 10px;
      font-size: 14px;
      border-radius: 5px;
      position: absolute;
      top: -10px;
      right: 10px;
      background: rgba(139, 139, 139, 1);
      color: #fff;
    }

    img, p, h4 {
      object-fit: contain;
      display: block;
      width: 90%;
    }
  }
}
</style>